import React, { useEffect } from 'react';
import styles from './manage-sites.module.scss';

import Loader from 'components/loader/loader';
import { connect, ConnectedProps } from 'react-redux';
import { useAppDispatch } from 'store';
import { getSiteCostInfoList } from 'store/actions/resource-site-actions';
import { RootState } from 'store/reducers';
import SiteCost from './site-cost';
import {Alert} from "@mui/lab";

const mapState = (state: RootState) => ({
  siteCostInfoList: state.infoSiteReducer.siteCostInfoList,
  isFetching: state.infoSiteReducer.isFetching,
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps;

const ManageSites = ({ siteCostInfoList, isFetching }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSiteCostInfoList());
  }, [getSiteCostInfoList]);
  if (siteCostInfoList) siteCostInfoList.sort((s1, s2) => s1.site.label.localeCompare(s2.site.label));
  return (
    <>
      <div className={styles.title}>MANAGE SITES</div>

      {isFetching ? (
        <div className={styles.loaderContainer}>
          <Loader/>
        </div>
      ) : (
        <div>
            {!siteCostInfoList || siteCostInfoList.length == 0 && (<div className={styles.container}>
              <Alert severity="error" className={styles.disclaimerMessage}>
                Error retreiving costs informations, please try later or contact support.
              </Alert>
            </div>)}
          <div className={styles.container}>
            <Alert severity="warning" className={styles.disclaimerMessage}>
              Prices are not fetched in real time. It may take up to an hour before you see an update.
            </Alert>
          </div>
          <div className={styles.container}>
            {siteCostInfoList && siteCostInfoList.map(site => (<SiteCost siteCost={site}
              displayDetails
              isManager
              isUserCostsOnly={false}
              key={site.site.id} />)
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default connector(ManageSites);
