import { 
  CLOSE_FILE_SHARE_MODAL, 
  FileShareActionType, 
  OPEN_FILE_SHARE_MODAL, 
  SET_EMAILS_TO_SHARE_FILE 
} from 'store/actions/file-share-actions';
import { FileDTO } from 'types';

export type FileShareState = {
  siteId: number | null,
  selectedProjectId: number | null,
  selectedFile: FileDTO | undefined,
  emails: string[],

};

const initialState: FileShareState = {
  siteId: null,
  selectedProjectId: null,
  selectedFile: undefined,
  emails: [],
};

const fileShareReducer = (state = initialState, action: FileShareActionType): FileShareState => {
  switch (action.type) {
    case OPEN_FILE_SHARE_MODAL:
      return {
        ...initialState,
        siteId: action.siteId,
        selectedFile: action.selectedFile,
      };

    case CLOSE_FILE_SHARE_MODAL:
      return {
        ...initialState,
      };
    case SET_EMAILS_TO_SHARE_FILE:
      return {
        ...state,
        emails: action.emails || [],
      };
    default:
      return {
        ...state,
      };
  }
};

export default fileShareReducer;
