import React from 'react';

import { connect, ConnectedProps } from 'react-redux';

import FileUploadDialog from 'components/file-upload/file-upload-dialog';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'store';
import { addFileUploadAction } from 'store/actions/file-upload-actions';
import { showModal } from 'store/actions/modal-actions';
import { ResourceAccessNode } from 'types';
import styles from './add-file-upload-styles.module.scss';

const mapDispatch = (dispatch) => bindActionCreators(
  {
    addFileUploadAction,
  },
  dispatch
);

const connector = connect(null, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  site: ResourceAccessNode,
  project?: ResourceAccessNode,
  children: React.ReactNode | null,
  onClose: () => void;
};

type Props = ReduxProps & OwnProps;

const FileUploadButton = ({ site, project, children, onClose }: Props) => {

  const dispatch = useAppDispatch();

  const handleClickOpen = (event) => {
    dispatch(showModal((<FileUploadDialog site={site} project={project}/>)));
    event.stopPropagation();
    onClose();
  };


  return (
    <div onClick={handleClickOpen} className={styles.menu_item_content}>
      <div>{children}</div>
    </div>
  );
};


export default connector(FileUploadButton);
