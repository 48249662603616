import { BackupPolicyDTO } from 'types';

export const BACKUP_POLICY_DISABLED: BackupPolicyDTO = {
  backupsEnabled: false,
};

export const ANF_NATIVE_BACKUP_POLICY: BackupPolicyDTO = {
  backupsEnabled: true,
  dailyBackupsToKeep: 14,
  weeklyBackupsToKeep: 5,
  monthlyBackupsToKeep: 12,
};

export const BACKUP_TYPES = {
  NO_BACKUP: 'none',
  ANF_NATIVE_BACKUP: 'native',
  ANF_FUNCTION: 'function-anf',
  AZF_FUNCTION: 'function-azf',
};