import React, { useState } from 'react';

import { Tooltip, IconButton } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Loader from 'components/loader/loader';

import styles from './notification-mark-as-read-button.module.scss';

import notificationService from 'services/notification-service';

type Props = {
  notificationId?: number,
  markAsReadAllUserNotifications?: boolean,
  markAsReadCallBack: () => void
};

const NotificationMarkAsReadButton = ({ notificationId, markAsReadAllUserNotifications, markAsReadCallBack }: Props) => {
  const [inProgress, setInProgress] = useState(false);
  const tooltipTitle = markAsReadAllUserNotifications ? 'Mark as read all notifications' : 'Mark as read notification';
  const testId = markAsReadAllUserNotifications ? 'mark-as-read-button-0' : `mark-as-read-button-${notificationId}`;

  const afterUpdate = () => {
    if (markAsReadCallBack) {
      // Do not set in progress to false, as the callback should update the status
      // If no callback, stop the progress.
      markAsReadCallBack();
    }
  };

  const markAsReadActionClick = () => {
    setInProgress(true);
    if (markAsReadAllUserNotifications) {
      notificationService.markAsReadAllUserNotifications().then(afterUpdate).finally(() => setInProgress(false));
    } else if (notificationId){
      notificationService.markAsReadNotification(notificationId).then(afterUpdate).finally(() => setInProgress(false));
    }
  };

  return (
    !inProgress ? (
      <IconButton data-testid={testId} className={styles.manage_notification__toolbarIcon} onClick={markAsReadActionClick}>
        <Tooltip title={tooltipTitle} placement='bottom'>
          <VisibilityOffIcon/>
        </Tooltip>
      </IconButton>
    ) : <Loader className={styles.mark_as_read_button__loader_icon} />
  );
};

export default NotificationMarkAsReadButton;
