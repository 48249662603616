import React, { createRef, Ref, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import IconAddUsers from '@mui/icons-material/GroupAdd';
import { Button, DialogTitle, DialogContent, IconButton, DialogActions, Grid, Dialog } from '@mui/material';

import UsersSelectorInput, { ValidateInput } from 'components/user/users-selector-input';
import {
  closeFileShareModal,
  openFileShareModal,
  setEmailsToAddAction,
  shareFileAction
} from 'store/actions/file-share-actions';
import { RootState } from 'store/reducers';
import { FileDTO } from 'types';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'store';


const mapState = (state: RootState) => ({
  selectedFile: state.fileShareReducer.selectedFile,
  selectedEmails: state.fileShareReducer.emails,
});

const mapDispatch = (dispatch) => bindActionCreators(
  {
    openFileShareModal,
    closeFileShareModal,
    setEmailsToAddAction,
  },
  dispatch
);

const connector = connect(mapState, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  siteId: number,
  fileToShare: FileDTO
};

type Props = ReduxProps & OwnProps;

const FileShare = ({
  siteId, fileToShare, selectedFile, selectedEmails, openFileShareModal, closeFileShareModal, setEmailsToAddAction,
}: Props) => {
  const [userEmailInput, setUserEmailInput] = useState('');
  const usersSelectorInputRef: Ref<ValidateInput> = createRef();

  const dispatch = useAppDispatch();
  const openModal = () => {
    openFileShareModal(siteId, fileToShare);
  };
  
  const shareFileByEmail = () => {
    // Validate current input in component before sendind request
    const isValidInput = usersSelectorInputRef.current?.validateInput();
    if (isValidInput) {
      dispatch(shareFileAction());
    }
  };

  const handleEmailChange = (inputFieldValue) => {
    setEmailsToAddAction(inputFieldValue);
  };

  const handleUserEmailInputChange = (value) => {
    setUserEmailInput(value);
  };
  
  return (
    <div>
      <IconButton className='fileshare-button' color='primary' edge='end' aria-label='comments' onClick={openModal}>
        <IconAddUsers/>
      </IconButton>

      <Dialog classes={{ paper: 'dialog-container' }} maxWidth='md' onClose={closeFileShareModal} className='dialog'
              open={fileToShare.shortName === selectedFile?.shortName}>
        <DialogTitle>
          <div className='dialogTitle'>
            <IconAddUsers className='icon'/>
            <div className='modalTitle'>Share File</div>
          </div>
          <div className='instancesharing-name'>{fileToShare.shortName || ''}</div>
        </DialogTitle>
        <DialogContent classes={{ root: 'overflow-y-visible' }}>
          <div className='invite-users-label'>Users</div>
          <UsersSelectorInput ref={usersSelectorInputRef} placeholder='add email...' emailOnly={true} onChange={handleEmailChange} onInputChange={handleUserEmailInputChange}/>
        </DialogContent>
        <DialogActions>
          <Grid container alignItems='stretch' direction='row' justifyContent='flex-end' className='dialog-actions'>
            <Grid item>
              <Button onClick={closeFileShareModal} className='cancel-button' sx={{ color: 'black' }}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={shareFileByEmail} variant='contained' color='primary' className='validation-button'
                      disabled={selectedEmails.length === 0 && !userEmailInput}>
                VALIDATE
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connector(FileShare);
