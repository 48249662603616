import React, { useEffect, useState } from 'react';

import Loader from 'components/loader/loader';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import styles from './manage-notifications.module.scss';
import { FilterNotificationObject, NotificationDTO, PaginatedNotificationsDTO, SortObject } from 'types';
import notificationService from 'services/notification-service';
import { formatDateFromMillis, formatDateTimeFromMillis } from 'services/date-service';
import NotificationDeleteButton from './action/notification-delete-button';
import NotificationMarkAsReadButton from './action/notification-mark-as-read-button';

const ManageNotifications = () => {

  // Constants used to sort in backend
  const SORT_NAME_CREATED_DATE = 'createdDate';
  const SORT_NAME_MESSAGE_LEVEL = 'messageLevel';

  // Columns names and NotificationRow properties need to be identical
  const NAME_COLUMN_NOTIFICATION_ID = 'notificationId';
  const NAME_COLUMN_READ_STATUS = 'readStatus';
  const NAME_COLUMN_SHORT_MESSAGE = 'shortMessage';
  const NAME_COLUMN_LONG_MESSAGE = 'longMessage';
  const NAME_COLUMN_SEVERITY = 'severity';
  const NAME_COLUMN_CREATED_DATE_IN_MILLIS = 'createdDateInMillis';

  // Map column names with sort constants
  const mapColumnNamesSortNames = new Map<string, string>();
  mapColumnNamesSortNames.set(NAME_COLUMN_CREATED_DATE_IN_MILLIS, SORT_NAME_CREATED_DATE);
  mapColumnNamesSortNames.set(NAME_COLUMN_SEVERITY, SORT_NAME_MESSAGE_LEVEL);
  
  type NotificationRow = {
    notificationId: number,
    readStatus: boolean,
    shortMessage: string,
    longMessage: string,
    severity: string,
    createdDateInMillis: number
  };

  const [localData, setLocalData] = useState<NotificationRow[]>([]);
  const [isReady, setReady] = React.useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pendingUpdates, setPendingUpdates] = useState<string[]>([]);
  const [tableState, setTableState] = useState<{ rowsPerPage: number, sortOrder: SortObject, filterList: string[], searchText: string, page: number }>({
    page: 0,
    rowsPerPage: 25,
    sortOrder: {
      name: NAME_COLUMN_CREATED_DATE_IN_MILLIS,
      direction: 'desc',
    },
    filterList: [],
    searchText: '',
  });

  useEffect(() => { 
    (async () => {
      if (!isReady) {
        forceRefresh();
      }
    })();
  }, []);

  const transform = (notifications: NotificationDTO[]) : NotificationRow[] => {
    if (notifications) {
      return notifications.map(notification => ({
        notificationId: notification.id,
        readStatus: notification.readStatus,
        shortMessage: notification.shortDescription,
        longMessage: notification.longDescription ? notification.longDescription : '',
        severity: notification.messageLevel,
        createdDateInMillis: notification.createdDateInMillis,
      }));
    } else {
      return [];
    }
  };

  const updateState = (paginatedNotifications: PaginatedNotificationsDTO) => {
    setCount(paginatedNotifications.totalElements);
    setPage(paginatedNotifications.currentPage);
  };

  const endOfReload = () => {
    setReady(true);
  };

  const forceRefresh = () => {
    changePage(tableState.page, tableState.rowsPerPage, tableState.sortOrder, tableState.filterList);
  };

  const mapSortObjectForBackendApiCall = (sortObject: SortObject): SortObject => {
    let newSortObject = sortObject;

    if (sortObject) {
      let sortName = mapColumnNamesSortNames.get(sortObject.name);
      
      if (!sortName) {
        sortName = SORT_NAME_CREATED_DATE;
      }

      newSortObject = {
        name: sortName,
        direction: sortObject.direction,
      };
    }

    return newSortObject;
  };

  const mapFilterNotificationObjectForBackendApiCall = (filterList): FilterNotificationObject => {
    const filterNotificationObject: FilterNotificationObject = {
      severity: typeof filterList[INDEX_COLUMN_SEVERITY] !== 'undefined' && filterList[INDEX_COLUMN_SEVERITY][0] !== undefined ? filterList[INDEX_COLUMN_SEVERITY][0] : '',
      unreadOnly: typeof filterList[INDEX_COLUMN_FILTER_UNREAD_ONLY] !== 'undefined' && filterList[INDEX_COLUMN_FILTER_UNREAD_ONLY].length > 0,
    };
    return filterNotificationObject;
  };

  const changePage = (newPage: number, rowsPerPage: number, sortOrder: SortObject, filterList) => {
    setPendingUpdates(pendingUpdates.concat('load'));
    const sortOrderForBackendApiCall = mapSortObjectForBackendApiCall(sortOrder);
    const filterNotificationForBackendApiCall = mapFilterNotificationObjectForBackendApiCall(filterList);
    
    notificationService.getNotificationsByUser(newPage, rowsPerPage, sortOrderForBackendApiCall, filterNotificationForBackendApiCall).then(paginatedNotifications => {
      updateState(paginatedNotifications);
      setLocalData(transform(paginatedNotifications.notifications));
      setPendingUpdates([]);
    })
      .finally(endOfReload);
  };

  //Used for filters
  const allMessageLevels = ['TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR'];

  // Columns definitions, column order is important
  const INDEX_COLUMN_NOTIFICATION_ID = 0;
  const INDEX_COLUMN_READ_STATUS = 1;
  const INDEX_COLUMN_SEVERITY = 4;
  const INDEX_COLUMN_FILTER_UNREAD_ONLY = 7;
  const headCells = [
    {
      name: NAME_COLUMN_NOTIFICATION_ID,
      options: {
        display: 'excluded',
        sort: false,
        filter: false,
      },
    },
    {
      name: NAME_COLUMN_READ_STATUS,
      numeric: false,
      label: 'Read Status',
      options: {
        display: 'excluded',
        sort: false,
        filter: false,
      },
    },
    {
      name: NAME_COLUMN_SHORT_MESSAGE,
      numeric: false,
      label: 'Short Message',
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: NAME_COLUMN_LONG_MESSAGE,
      numeric: false,
      label: 'Long Message',
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: NAME_COLUMN_SEVERITY,
      numeric: false,
      label: 'Severity',
      options: {
        sort: true,
        sortDescFirst: true,
        filterType: 'dropdown',
        filterOptions: {
          names: allMessageLevels,
        },
        setCellProps: () => {
          return {
            className: styles.manage_notification__severityColumn,
          };
        },
      },
    },
    {
      name: NAME_COLUMN_CREATED_DATE_IN_MILLIS,
      numeric: false,
      label: 'Date',
      options: {
        sort: true,
        sortDescFirst: true,
        filter: false,
        setCellProps: () => {
          return {
            className: styles.manage_notification__dateColumn,
          };
        },
        customBodyRender: (value) => {
          return (
            <div>
              <Tooltip title={value && formatDateTimeFromMillis(value)}>
                <div>{value && formatDateFromMillis(value)}</div>
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: '',
      label: '', // "Actions" like Delete...
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false, // Cannot hide it !
        setCellProps: () => {
          return {
            className: styles.manage_notification__actionColumn,
          };
        },
        customBodyRender: (value, allData) => {
          const row = allData.rowData;
          const notificationId = row[INDEX_COLUMN_NOTIFICATION_ID];
          return (
            <div className={styles.manage_notification__actionContainer}>
              <NotificationMarkAsReadButton notificationId={notificationId} markAsReadCallBack={forceRefresh}/>
              <NotificationDeleteButton notificationId={notificationId} deleteCallBack={forceRefresh}/>
            </div>
          );
        },
      },
    },
    {
      name: 'unreadOnly', //Only for filter
      label: ' ',
      options: {
        filterType: 'checkbox',
        filterOptions: {
          names: ['Unread Only'],
        },
        sort: false,
        display: 'excluded',
      },
    },
  ];

  const options = {
    print: false,
    filter: true,
    serverSide: true,
    count: count,
    page: page,
    // searchText: tableState.searchText,
    elevation: 0,
    rowsPerPageOptions: [10, 25, 50, 100],
    draggableColumns: {
      enabled: true,
    },
    selectableRows: 'none',
    sortOrder: tableState.sortOrder,
    filterList: tableState.filterList,
    sortFilterList: false,
    customFilterDialogFooter: () => {
      return (
        <>
          <div className={styles.manage_notification__customFilterFooter}></div>
        </>
      );
    },
    rowsPerPage: tableState.rowsPerPage,
    customToolbar: () => {
      return (
        <>
          <IconButton onClick={forceRefresh} className={styles.manage_notification__toolbarIcon}>
            <Tooltip title='Refresh' placement='bottom'>
              <RefreshIcon/>
            </Tooltip>
          </IconButton>
          <NotificationMarkAsReadButton markAsReadAllUserNotifications={true} markAsReadCallBack={forceRefresh}/>
          <NotificationDeleteButton deleteAllUserNotifications={true} deleteCallBack={forceRefresh}/>
        </>
      );
    },
    onTableChange: (action, newTableState) => {
      switch (action) {
        case 'changePage':
        case 'changeRowsPerPage':
        case 'search':
        case 'filterChange':
        case 'sort':
        case 'resetFilters':
          changePage(newTableState.page, newTableState.rowsPerPage, newTableState.sortOrder, newTableState.filterList);
          setTableState(newTableState);
          break;
        default:
          break;
      }
    },
    setRowProps: row => { 
      if (!row[INDEX_COLUMN_READ_STATUS]) {
        return {
          className: styles.manage_notification__unread,
        };
      }
    },
  };
  
  return (
    <div className={styles.manage_notification__topContainer}>
    {!isReady && (
      <div data-testid='notification-loader-container' className={styles.manage_notification__loadingSpinner}>
        <Loader/>
      </div>
    )}
    {
      isReady && (
        <>
          <div data-testid='notification-data-table-container' className={styles.manage_notification__tableContainer}>
            <MUIDataTable
              title={
                <>
                  <span className={styles.manage_notification__title}>Notifications</span>
                  {pendingUpdates.length > 0 && (
                    <>
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: 'relative', top: 4 }}/>
                    </>
                  )}
                </>
              }
              data={localData}
              columns={headCells}
              options={options}/>
          </div>
        </>
      )
    }
  </div>
  );
};
  
export default ManageNotifications;