import { NotificationDTO, PaginatedNotificationsDTO, SortObject, FilterNotificationObject } from 'types';
import instance from './axios';
import toastService from './toast-service';
import qs from 'qs';

export default {

  getNotificationsByUser(page: number, rowsPerPage: number, sortCriteria: SortObject, filterNotificationObject: FilterNotificationObject) : Promise<PaginatedNotificationsDTO>{
    const url = `api/resource/notifications?page=${page}&size=${rowsPerPage}`;
    return instance.get(url, {
      params: {
        name: sortCriteria.name,
        direction: sortCriteria.direction,
        messageLevel: filterNotificationObject.severity,
        unreadOnly: filterNotificationObject.unreadOnly,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { allowDots: true });
      },
    })
      .then(res => res.data)
      .catch(() => {
        toastService.error('Error while getting notifications');
        return Promise.reject();
      });
  },

  countUnreadNotificationsByUser() : Promise<number>{
    const url = '/api/resource/notifications/unread/count';
    return instance.get(url)
      .then(res =>  res.data)
      .catch(() => {
        toastService.error('Error while fetching notifications');
        return Promise.reject();
      });
  },

  deleteNotification(notificationId:number) : Promise<void>{
    const url = `/api/resource/notification/${notificationId}`;
    return instance.delete(url)
      .then(res => res.data)
      .catch(() => {
        toastService.error('Error while deleting notification');
        return Promise.reject();
      });
  },

  deleteAllUserNotifications() : Promise<void>{
    const url = '/api/resource/notifications';
    return instance.delete(url)
      .then(res => res.data)
      .catch(() => {
        toastService.error('Error while deleting all notifications');
        return Promise.reject();
      });
  },

  markAsReadNotification(notificationId:number) : Promise<NotificationDTO>{
    const url = `/api/resource/notification/${notificationId}/status/true`;
    return instance.patch(url)
      .then(res => res.data)
      .catch(() => {
        toastService.error('Error while marking notification as read');
        return Promise.reject();
      });
  },

  markAsReadAllUserNotifications() : Promise<void>{
    const url = '/api/resource/notifications/status/true';
    return instance.patch(url)
      .then(res => res.data)
      .catch(() => {
        toastService.error('Error while marking all notifications as read');
        return Promise.reject();
      });
  },
  
};
  