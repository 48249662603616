import fileService from 'services/file-service';
import { AppThunk } from 'store/reducers';
import { FileDTO, ShareFileInfoDTO } from 'types';

export const OPEN_FILE_SHARE_MODAL = 'OPEN_FILE_SHARE_MODAL';
export const CLOSE_FILE_SHARE_MODAL = 'CLOSE_FILE_SHARE_MODAL';
export const SET_EMAILS_TO_SHARE_FILE = 'SET_EMAILS_TO_SHARE_FILE';

export type OpenFileShareModal = {
  type: typeof OPEN_FILE_SHARE_MODAL,
  siteId: number,
  selectedFile: FileDTO,
};

export type CloseFileShareModal = {
  type: typeof CLOSE_FILE_SHARE_MODAL,
};

export type SetEmailsToAddAction = {
  type: typeof SET_EMAILS_TO_SHARE_FILE,
  emails: string[]
};

export const openFileShareModal = (siteId: number, selectedFile: FileDTO): OpenFileShareModal => ({ type: OPEN_FILE_SHARE_MODAL, siteId, selectedFile });
export const closeFileShareModal = (): CloseFileShareModal => ({ type: CLOSE_FILE_SHARE_MODAL });

export const setEmailsToAddAction = (emailsToSHareWith: string[] = []): SetEmailsToAddAction => ({
  type: SET_EMAILS_TO_SHARE_FILE,
  emails: emailsToSHareWith.filter(mail => mail.indexOf('@') !== -1),
});

export const shareFileAction = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const { selectedFile, siteId, emails } = getState().fileShareReducer;

  if (selectedFile && siteId?.toString) {
    const requestBody : ShareFileInfoDTO = {
      emails: emails,
      fileName: selectedFile.shortName,
      fileSize: selectedFile.size,
    };
    fileService.shareFile(siteId, requestBody).then(() => dispatch(closeFileShareModal()));
  }
};

export type FileShareActionType = OpenFileShareModal 
| CloseFileShareModal
| SetEmailsToAddAction;