import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { LinearProgress, IconButton } from '@mui/material';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import IconClose from '@mui/icons-material/Clear';
import IconCancel from '@mui/icons-material/HighlightOff';
import IconCompleted from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import './file-upload-component.scss';
import { deleteFileUploadAction } from 'store/actions/file-upload-actions';

import fileService from 'services/file-service';
import { formatBytes } from 'services/size-format-service';
import { BlobServiceClient } from '@azure/storage-blob';
import { UploadDTO } from 'types';
import { bindActionCreators } from 'redux';

const source = new Map(); 

//below this file size, file is sent in one piece
const MAX_SIZE_SINGLE_SHOT = 1024 * 1024 * 50;
//block size for small files
const SMALL_FILE_BLOCK_SIZE = 1024 * 1024 * 4;
//block size for big files
const BIG_FILE_BLOCK_SIZE = 1024 * 1024 * 50;
//limit between small files and big files
const FILE_SIZE_THRESHOLD = 1024 * 1024 * 50;
//Max number of parallel upload, given block size
const FILE_UPLOAD_CONCURRENCY = 10;

const mapDispatch = (dispatch) => bindActionCreators(
  {
    deleteFileUploadAction,
  },
  dispatch
);

const connector = connect(null, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  fileUploadData: UploadDTO
};

type Props = ReduxProps & OwnProps;

// We use this only for test.
const FileUploadComponent = ({ fileUploadData, deleteFileUploadAction }: Props) => {
  const { siteId, uploadId, file } = fileUploadData;
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [uploadCancelled, setUploadCancelled] = useState(false);
  const [loadedPercentage, setLoadedPercentage] = useState(0);
  const [uploadDuration, setUploadDuration] = useState(0);

  const uploadChunks = () => {
    //Manage abort controller
    //start time in seconds
    const startTime = new Date().getTime();
    const controller = new AbortController();
    source.set(uploadId, controller);
    // Get Blob information
    fileService.getUploadblobInfo(siteId, file.name)
      .then(response => {
        const blobinfo = response.data;
        // Get BlobService
        const blobService = new BlobServiceClient(
          blobinfo.storageURLWithToken
        );
        // Get Container
        const containerClient = blobService.getContainerClient(blobinfo.containerName);
        // Get block blob (create empty if not exist)
        //TODO completely remove blobPrefix when delete import feature flag
        const blobName: string = (blobinfo.blobPrefix ? blobinfo.blobPrefix : '') + file.name;
        const blobClient = containerClient.getBlockBlobClient(blobName);
        // Upload blob data
        blobClient.uploadData(file, {
          onProgress: (p) => {
            setLoadedPercentage(Math.round((p.loadedBytes / file.size) * 100));
          },
          maxSingleShotSize: MAX_SIZE_SINGLE_SHOT,
          blockSize: file.size < FILE_SIZE_THRESHOLD ? SMALL_FILE_BLOCK_SIZE : BIG_FILE_BLOCK_SIZE,
          abortSignal: controller.signal,
          concurrency: FILE_UPLOAD_CONCURRENCY,
        }).then(() => {
          setUploadCompleted(true);
          setUploadDuration(Math.round((new Date().getTime() - startTime) / 1000));
          source.delete(uploadId);
        },
        reason => {
          if (reason.name === 'AbortError') {
            setUploadCancelled(true);
            source.delete(uploadId);
          } else {
            setUploadFailed(true);
            source.delete(uploadId);
          }
        });
      })
      .catch(() => {
        setUploadFailed(true);
        source.delete(uploadId);
      });
  };

  useEffect(uploadChunks, []);

  const cancelUpload = () => {
    //abort a specific upload
    if (source.has(uploadId)) {
      source.get(uploadId).abort();
      source.delete(uploadId);
    }
  };

  const closeFileUpload = () => {
    if (source.has(uploadId)) {
      source.get(uploadId).abort();
      source.delete(uploadId);
    }
    deleteFileUploadAction(uploadId);
  };

  return (
    <div className='file-upload-component'>
      <div className='header'>
        upload file
        <div className='close-container'>
          <IconButton className='icon-close' onClick={closeFileUpload}>
            <IconClose/>
          </IconButton>
        </div>
      </div>

      <div className='body'>
        <div className='file-name-container'>
          <FileIcon className='file-icon'/>
          <div className='file-name'>{file.name} </div>
          <div>&nbsp;</div>
          <div className='file-size'>
            {
              formatBytes(file.size)
            }
          </div>
          <div className='action-icon'>
            {
              !uploadCompleted && !uploadFailed && !uploadCancelled ? (
                  <IconButton onClick={cancelUpload}>
                    <IconCancel className='icon-cancel'/>
                  </IconButton>
              ) :
                null
            }
            {
              uploadCompleted ? (
                  <IconButton disabled>
                    <IconCompleted
                      className='icon-completed'/>
                  </IconButton>
              ) :
                null
            }
            {
              uploadFailed ? (
                  <IconButton disabled>
                    <ErrorIcon className='icon-failed'/>
                  </IconButton>
              ) :
                null
            }
            {
              uploadCancelled ? (
                  <IconButton disabled>
                    <CancelIcon className='icon-cancelled'/>
                  </IconButton>
              ) :
                null
            }
          </div>
        </div>
        <LinearProgress variant='determinate' value={loadedPercentage}/>
        <div className='progress-info'>
          {
            !uploadCompleted && !uploadFailed && !uploadCancelled &&
            <span>{`${Math.round(loadedPercentage)}% done`}</span>
          }
          {
            uploadCompleted && <div className='upload-completed'>Completed in {uploadDuration} seconds</div>
          }
          {
            uploadFailed && <div className='upload-error-msg'>Failed</div>
          }
          {
            uploadCancelled && <div className='upload-error-msg'>Cancelled</div>
          }
        </div>
      </div>
    </div>
  )
  ;
};

export default connector(FileUploadComponent);
