import { ReactNode } from 'react';

export const SHOW_MODAL = 'SHOW_MODAL';

export type ShowModalAction = {
  type: typeof SHOW_MODAL,
  component: ReactNode | undefined
};

export const showModal = (component: ReactNode | undefined): ShowModalAction => ({ type: SHOW_MODAL, component });

export type ModalActionType = ShowModalAction;