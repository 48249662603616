import {useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {bindActionCreators} from 'redux';
import {useAppDispatch} from 'store';
import {updateVmInstanceListPollingAction} from 'store/actions/vm-instance-actions';

import styles from './vm-instance-list.module.scss';

import TablePagination from '@mui/material/TablePagination';
import Loader from 'components/loader/loader';
import VMComponent from 'components/vm/vm-component/vm-component';
import WelcomeMessage from 'components/welcome-message/welcome-message';
import {RootState} from 'store/reducers';
import {Alert} from "@mui/lab";

const mapState = (state: RootState) => ({
    vmInstanceList: state.vmInstancesReducer.sessions,
    isFetchingVMInstanceList: state.vmInstancesReducer.isFetching,
    currentPage: state.vmInstancesReducer.currentPage,
    pageSize: state.vmInstancesReducer.pageSize,
    totalElements: state.vmInstancesReducer.totalElements,
    loggedUser: {
        id: state.loggedUserReducer.id || '',
        firstname: state.loggedUserReducer.firstname || '',
        lastname: state.loggedUserReducer.lastname || '',
    },
    sites: state.resourcesReducer.siteList,
});

const mapDispatch = (dispatch) => bindActionCreators(
    {
        updateVmInstanceListPollingAction,
    },
    dispatch
);

const connector = connect(mapState, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps;

const VMInstanceList = ({
                            isFetchingVMInstanceList,
                            vmInstanceList,
                            sites,
                            loggedUser,
                            currentPage,
                            pageSize,
                            totalElements,
                        }: Props) => {
    const dispatch = useAppDispatch();
    const [page, setPage] = useState(currentPage);
    const [rowsPerPage, setRowsPerPage] = useState(pageSize);

    const hasExpiredSite = sites.some(site => site.isExpired);

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        updateVmInstanceListPolling(0, newRowsPerPage);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPageNumber: number
    ) => {
        setPage(newPageNumber);
        dispatch(updateVmInstanceListPollingAction(newPageNumber, rowsPerPage));
    };

    const updateVmInstanceListPolling = (pageNumber: number, sessionsPerPage: number) => {
        dispatch(updateVmInstanceListPollingAction(pageNumber, sessionsPerPage));
    };

    if (isFetchingVMInstanceList) {
        return (
            <div className={styles.loaderContainer}>
                <Loader/>
            </div>
        );
    }

    if (sites.length === 0 && loggedUser.id && vmInstanceList.length === 0) {
        return <WelcomeMessage loggedUser={loggedUser}/>;
    }

    return (
        <>
          <div className={styles.titleContainer}>
            <div className={styles.title + ' ' + styles.unselectable}>
              SESSIONS
            </div>
            <div> {hasExpiredSite && <Alert severity="warning" className={styles.alert}>
              You rights are expired on some of your sites. Click on orange i to find a manager to
              contact.</Alert>}
            </div>
          </div>
          <div className={styles.instanceListContainer}>
            {vmInstanceList.map((vmInstance, index) => (
                <VMComponent
                    key={vmInstance.sessionId || index}
                    vmInstance={vmInstance}
                        loggedUserId={loggedUser.id}/>
                ))}
            </div>
            <TablePagination
                component='div'
                className={styles.tablePagination}
                count={totalElements}
                page={page}
                onPageChange={(event, page) => handleChangePage(event, page)}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage='Sessions per page'
                onRowsPerPageChange={event => handleChangeRowsPerPage(event)}/>
        </>
    );
};

export default connector(VMInstanceList);
