import { useEffect, useState } from 'react';
import { ConnectedProps, connect, useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';

import styles from './app-styles.module.scss';

import { getDisclaimerAgreement, getUserAvatar, initLoggedUser } from 'store/actions/logged-user-actions';
import { startPollingGetNotificationsAction } from 'store/actions/notification-actions';
import { fetchSiteList } from 'store/actions/resource-site-actions';
import { getUserBillingLightAction } from 'store/actions/user-billing-actions';
import { startPollingGetVmInstanceListAction } from 'store/actions/vm-instance-actions';

import ProjectDownload from 'components/download/project-download';
import FileUploadContainer from 'components/file-upload/file-upload-container';
import FileUploadRedirectionWarningModal from 'components/file-upload/file-upload-redirection-warning-modal';
import Header from 'components/header/header';
import ManageSites from 'components/manage-sites/manage-sites';
import Sidebar from 'components/menu/sidebar';
import ManageNotifications from 'components/notification/manage-notifications';
import ProjectInfo from 'components/project-info/project-info';
import SiteInfo from 'components/resource/resource-information/site-info';
import UserAgreeDisclaimer from 'components/user-agree-disclaimer/user-agree-disclaimer';
import ManageUsers from 'components/user/manage-users';
import UserProfile from 'components/user/user-profile';
import VMInstanceList from 'components/vm/vm-instance-list/vm-instance-list';
import ZoneInfo from 'components/zone-info/zone-info';

import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'store';
import { RootState } from 'store/reducers';
import { getModal } from 'store/selectors/modal-selector';

import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import Loader from 'components/loader/loader';
import TestLatency from 'components/test-latency/testLatency';
import getAccessToken from 'services/access-token-service';
import { loginRedirect } from 'services/authentification-service';

const mapState = (state: RootState) => ({
  fileUploadList: state.fileUploadReducer.fileUploadList,
  showDisclaimer: state.loggedUserReducer.id && !state.loggedUserReducer.agreeDisclaimer,
  showRedirectionWarningModal: state.fileUploadReducer.showRedirectionWarningModal,
});

const mapDispatch = (dispatch) => bindActionCreators(
  {
    initLoggedUser,
  },
  dispatch
);

const connector = connect(mapState, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps;

import { makeServer } from 'dev/server';

if (process.env.NODE_ENV === 'development') {
  makeServer({ environment: 'development' });
}

// We use this export only for tests
const App = (
  {
    fileUploadList,
    showDisclaimer,
    showRedirectionWarningModal,
    initLoggedUser,
  }: Props) => {

  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const modal = useSelector(getModal);

  const { accounts, inProgress, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [accessToken, setAccessToken] = useState('');

  function processToken(token: string) {
    setAccessToken(token);
    history.push(location.pathname + location.search);
    dispatch(startPollingGetVmInstanceListAction(0, 25));
    dispatch(startPollingGetNotificationsAction());
    initLoggedUser(token);
    dispatch(fetchSiteList());
    dispatch(getUserAvatar());
    dispatch(getUserBillingLightAction());
    dispatch(getDisclaimerAgreement());
  }

  useEffect(() => {
    if (!accessToken && process.env.NODE_ENV === 'development') {
      // https://10015.io/tools/jwt-encoder-decoder
      // Dw/G:+@%VR[a$LV,D4L{5+(4I}+zf+ER
      /*
{
  lastname: 'test',
  firstname: 'user',
  prefered_username: 'user.test@totalenergies.com',
  name: 'test user',
  id: 'test',
 "user/IGG": 'J1234567'
}
       */

      processToken(
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.' +
        'eyJsYXN0bmFtZSI6InRlc3QiLCJmaXJzdG5hbWUiOiJ1c2VyIiwicHJlZmVyZWRfdXNlcm5hbWUiOiJ1c2VyLnRlc3RAdG90YWxlbmVyZ2llcy5' +
        'jb20iLCJuYW1lIjoidGVzdCB1c2VyIiwiaWQiOiJ0ZXN0IiwidXNlci9JR0ciOiJKMTIzNDU2NyIsImlhdCI6MTcxNTYxODQ0Mn0' +
        '.uffDshXgLJmJdP4NzOyYziuK8kYwsUUKjPs9GHfGFg8'
      );
      //console.log('No Auth in development mode');
    } else if (!accessToken && account && inProgress === InteractionStatus.None) {
      instance.setActiveAccount(account);
      const awaitToken = async () => {
        await getAccessToken().then(processToken).catch(err => console.error(err));
      };
      awaitToken();
    } else if (inProgress === InteractionStatus.None) {
      loginRedirect(accounts[0]);
    }
  }, [accounts, inProgress]);

  return (
    <div className={styles.appContent}>
      {modal}
      <Sidebar />
      <Header />
      <div className={styles.appContent}>
        <main className={styles.mainContent}>
          {!accessToken ? <Loader /> :
            <>
              <Route exact path='/' component={VMInstanceList} />
              <Route exact path='/manage-users' component={() => <ManageUsers />} />
              <Route exact path='/manage-sites' component={() => <ManageSites />} />
              <Route exact path='/user-profile' component={() => <UserProfile />} />
              <Route exact path='/manage-notifications' component={() => <ManageNotifications />} />

              <Route exact path='/download/:siteId' component={path => <ProjectDownload siteId={Number(path.match.params.siteId)}  />} />
              <Route exact path='/site-info/:siteId' component={path => <SiteInfo siteId={Number(path.match.params.siteId)} />} />
              <Route exact path='/zone-info/:zoneId' component={path => <ZoneInfo zoneId={path.match.params.zoneId} />} />
              <Route exact path='/project-info/:projectId' component={path => <ProjectInfo projectId={path.match.params.projectId} />} />
              <Route exact path='/vm/latency/:url' component={path => <TestLatency url={path.match.params.url} />} />
            </>
          }
        </main>

        {fileUploadList.length && <FileUploadContainer fileUploadList={fileUploadList} />}
        {showRedirectionWarningModal && <FileUploadRedirectionWarningModal />}
        {showDisclaimer && <UserAgreeDisclaimer />}
      </div>
    </div>
  );
};

export default connector(App);
