import zoneService from 'services/zone-service';
import { AppThunk } from 'store/reducers';
import { CreateZoneDTO, ResourceAccessNode, ZoneInfoDTO } from 'types';

/*
 * Add Zone
 */
export const REQUEST_ADD_ZONE = 'REQUEST_ADD_ZONE';
export const RECEIVE_ADD_ZONE = 'RECEIVE_ADD_ZONE';
export const ERROR_ADD_ZONE = 'ERROR_FETCH_ADD_ZONE';
export const RECEIVE_ZONE_FILESHARE_READY = 'RECEIVE_ZONE_FILESHARE_READY';
export const RECEIVE_ZONE_FILESHARE_FAILED = 'RECEIVE_ZONE_FILESHARE_FAILED';

export type RequestAddZoneAction = {
  type: typeof REQUEST_ADD_ZONE;
};

export type ReceiveAddZoneAction = {
  type: typeof RECEIVE_ADD_ZONE;
  siteResourcePath: string;
  zone: ResourceAccessNode;
};

export type ErrorAddZoneAction = {
  type: typeof ERROR_ADD_ZONE;
};

export type ReceiveZoneFileshareReadyAction = {
  type: typeof RECEIVE_ZONE_FILESHARE_READY;
  siteId: number;
  zoneId: number;
};

export type ReceiveZoneFileshareFailedAction = {
  type: typeof RECEIVE_ZONE_FILESHARE_FAILED;
  siteId: number;
  zoneId: number;
};

export const requestAddZoneAction = (): RequestAddZoneAction => ({ type: REQUEST_ADD_ZONE });
export const receiveAddZoneAction = (siteResourcePath: string, zone: ResourceAccessNode): ReceiveAddZoneAction => ({
  type: RECEIVE_ADD_ZONE,
  siteResourcePath,
  zone,
});
export const errorAddZoneAction = (): ErrorAddZoneAction => ({ type: ERROR_ADD_ZONE });
export const receiveZoneFileshareReadyAction = (siteId: number, zoneId: number) => ({
  type: RECEIVE_ZONE_FILESHARE_READY,
  siteId,
  zoneId,
});

export const receiveZoneFileshareFailedAction = (siteId: number, zoneId: number) => ({
  type: RECEIVE_ZONE_FILESHARE_FAILED,
  siteId,
  zoneId,
});

export const addZone =
  (body: CreateZoneDTO): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch(requestAddZoneAction());
    return zoneService
      .addZone(body)
      .then((createdZone) => {
        dispatch(receiveAddZoneAction(body.siteResourcePath, { ...createdZone, fileshareReady: false }));
        return createdZone;
      })
      .then((createdZone) => zoneService.getZoneFileshareStatusWithPolling(createdZone.siteId, createdZone.resourceId, dispatch))
      .catch(() => {
        dispatch(errorAddZoneAction());
      });
  };

/*
 * Delete Zone
 */
export const RECEIVE_DELETE_ZONE = 'RECEIVE_DELETE_ZONE';
export const RECEIVE_DELETE_ZONE_INSTANCES = 'RECEIVE_DELETE_ZONE_INSTANCES';

export type ReceiveDeleteZoneAction = {
  type: typeof RECEIVE_DELETE_ZONE;
  siteId: number;
  zoneId: number;
};

export type ReceiveDeleteZoneInstancesAction = {
  type: typeof RECEIVE_DELETE_ZONE_INSTANCES;
  zoneId: number;
};

export const receiveDeleteZoneAction = (siteId: number, zoneId: number) => ({
  type: RECEIVE_DELETE_ZONE,
  siteId,
  zoneId,
});
export const receiveDeleteZoneInstancesAction = (zoneId: number) => ({ type: RECEIVE_DELETE_ZONE_INSTANCES, zoneId });

export const deleteZone =
  (siteId: number, zoneId: number): AppThunk<Promise<void>> => async (dispatch) => {
    return zoneService.deleteZone(zoneId).then(() => {
      dispatch(receiveDeleteZoneAction(siteId, zoneId));
      dispatch(receiveDeleteZoneInstancesAction(zoneId));
    });
  };

/*
 * Fetch zone information
 */
export const REQUEST_ZONE_INFORMATION = 'REQUEST_ZONE_INFORMATION';
export const RECEIVE_ZONE_INFORMATION = 'RECEIVE_ZONE_INFORMATION';
export const ERROR_FETCH_ZONE_INFORMATION = 'ERROR_FETCH_ZONE_INFORMATION';

export type RequestZoneInformationAction = {
  type: typeof REQUEST_ZONE_INFORMATION;
};

export type ReceiveZoneInformationAction = {
  type: typeof RECEIVE_ZONE_INFORMATION;
  zoneInformation: ZoneInfoDTO;
};

export type ErrorFetchZoneInformationAction = {
  type: typeof ERROR_FETCH_ZONE_INFORMATION;
};

export const requestZoneInformationAction = (): RequestZoneInformationAction => ({ type: REQUEST_ZONE_INFORMATION });
export const receiveZoneInformationAction = (zoneInformation: ZoneInfoDTO): ReceiveZoneInformationAction => ({
  type: RECEIVE_ZONE_INFORMATION,
  zoneInformation,
});
export const errorFetchZoneInformationAction = (): ErrorFetchZoneInformationAction => ({ type: ERROR_FETCH_ZONE_INFORMATION });

export const getZoneInformation =
  (zoneId: number): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch(requestZoneInformationAction());

    return zoneService
      .getZoneInfo(zoneId)
      .then(zoneInformation => {
        dispatch(receiveZoneInformationAction(zoneInformation));
        return;
      })
      .catch(() => {
        dispatch(errorFetchZoneInformationAction());
      });
  };

export type ZoneActions =
  | RequestAddZoneAction
  | ReceiveAddZoneAction
  | ErrorAddZoneAction
  | ReceiveZoneFileshareReadyAction
  | ReceiveZoneFileshareFailedAction
  | ReceiveDeleteZoneAction
  | ReceiveDeleteZoneInstancesAction
  | RequestZoneInformationAction
  | ReceiveZoneInformationAction
  | ErrorFetchZoneInformationAction;
